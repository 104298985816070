import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const TeamMember = ({member}) =>  {
  const imageRes = member.featured_media ? member.featured_media.localFile.childImageSharp.fixed : null;
  const socials = member.acf.team_member_socials;

  return imageRes ? (
    <div
        className="team-member-component"
    >
        <Img resolutions={imageRes} key={imageRes.src} className="photo" />
        { socials !== null ? (<ul className="social-networks">
            {socials.map((social) => (
                <li key={social.social_url}>
                    <a
                        href={social.social_url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span className={`social-icon alternate ${social.social_type}`}></span></a>
                </li>
            ))}
        </ul>) : null}
        <h2>
            {member.title}
            <span> / {member.acf.team_member_position}</span>
        </h2>
        <hr />
    </div>
  ) : '';
};

TeamMember.propTypes = {
    member: PropTypes.object.isRequired,
};

export default TeamMember;