import React from "react";
import { graphql } from "gatsby";
import Layout from '../components/layout';
import PageHeader from '../components/page-header';
import TeamMember from '../components/team-member';

const AboutUsTemplate = ({data}) => {
    const page = data.wordpressPage;
    const pageTitle = `${data.site.siteMetadata.pageTitles.about} | ${data.site.siteMetadata.title}`;
    const members = data.allWordpressWpTeamMember.edges;

    return (
        <Layout>
            <PageHeader
                heading={page.title}
                subHeading={page.acf.subheading}
                pageTitle={pageTitle}
            />
            <div className="about-us-page-component">
                <div className="component-wrapper">
                    <div className="content-wrapper" dangerouslySetInnerHTML={{__html: page.content}}></div>
                    {members.map(({node}) => (
                        <TeamMember member={node} key={node.id} />
                    ))}
                </div>
            </div>
        </Layout>
    );
};

export default AboutUsTemplate;

export const pageQuery = graphql`
    query AboutUsTemplateQuery {
        site {
            siteMetadata {
                title
                pageTitles {
                    about
                }
            }
        }
        wordpressPage(wordpress_id: { eq: 5 }) {
            title
            content
            acf {
                subheading
            }
        }
        allWordpressWpTeamMember(
            sort: {
                fields: wordpress_id,
                order: ASC
            }
        ) {
            edges {
                node {
                    id
                    title
                    content
                    featured_media {
                        localFile {
                            childImageSharp {
                                fixed(width: 199){
                                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                    acf {
                        team_member_position
                        team_member_quote
                        team_member_socials {
                            social_type
                            social_url
                        }
                    }
                }
            }
        }
    }
`;