import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const PageHeader = ({heading, subHeading, pageTitle, seoMetaData}) => (
    <div className="page-header-component">
        {renderHelmet(pageTitle, seoMetaData)}
        <div className="component-wrapper">
            <h1 dangerouslySetInnerHTML={{__html: heading}}></h1>
            {subHeading && (<h2 dangerouslySetInnerHTML={{__html: subHeading}}></h2>)}
        </div>
    </div>
);

const renderHelmet = (
        pageTitle,
        {
            yoast_wpseo_title: title = '',
            yoast_wpseo_metadesc: description = '',
            yoast_wpseo_canonical: url = '',
            featured_image: image = ''
        } = {}
    ) => {
    return (
        <Helmet title={pageTitle ? pageTitle : null}>
            {description && (<meta name="description" content={description} />)}

            {/* OpenGraph tags */}
            {url         && (<meta property="og:url" content={url} />)}
            {title       && (<meta property="og:title" content={title} />)}
            {description && (<meta property="og:description" content={description} />)}
            {image       && (<meta property="og:image" content={image} />)}

            {/* Twitter Card tags */}
            {image       && (<meta name="twitter:card" content="summary_large_image" />)}
            {title       && (<meta name="twitter:title" content={title} />)}
            {description && (<meta name="twitter:description" content={description} />)}
            {image       && (<meta name="twitter:image" content={image} />)}
        </Helmet>
    );
};

PageHeader.propTypes = {
    heading:     PropTypes.string.isRequired,
    subHeading:  PropTypes.string,
    pageTitle:   PropTypes.string,
    seoMetaData: PropTypes.object,
};

export default PageHeader;